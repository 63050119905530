import React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
// import { Link } from 'react-router-dom';
import Link from '@mui/material/Link';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles({
    container: {
        width: '96%',
        '& .MuiBreadcrumbs-ol': {
            background: '#F5F5F5',
            width: '96%',
            // paddingLeft: '20px',
            margin: 'auto',
            paddingLeft: '20px'
        },
        '& .MuiBreadcrumbs-li , .MuiBreadcrumbs-separator': {
            color: '#65A518'
        },
        // '& .MuiTypography-root': {
        //     width: '100%'
        // },
        // '& .MuiBreadcrumbs-root': {
        //     width: '100%'
        // }
        // '&.css-63vwl1-MuiTypography-root-MuiBreadcrumbs-root': {
        //     width: '100%'
        // }
    }
});


const BreadcrumbsCustom = ({ items }) => {
    const styles = useStyles();
    const navigate = useNavigate();
    
    const goToPreviusStep = (url) => {
        if (!url) navigate(-1)
    }

    return (
        <Breadcrumbs aria-label="breadcrumb" className={styles.container}>
            {items.map((item, index) => 
                item.name !== items[items.length - 1].name
                    ?
                        <Link 
                            style={{cursor: 'pointer'}}
                            underline="hover" 
                            color="#65A518" 
                            onClick={() => goToPreviusStep(item.url)} 
                            href={item.url} 
                            key={index}
                        >
                            { item.name }
                        </Link>
                    :
                        <Typography sx={{color: '#565656'}} key={index}>
                            { item.name }
                        </Typography>
            )}
        </Breadcrumbs>
    )
}

export default BreadcrumbsCustom
