import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import { convertLength } from '@mui/material/styles/cssUtils';

const useStyles = makeStyles({
    button: {
        display: 'block',
        // border: '1px solid #1A1446',
        border: 'none',
        background: '#65A518 0% 0% no-repeat padding-box',
        color: '#FFFFFF',
        padding: '10px 24px 10px 24px',
        height: '50px',
        // borderRadius: '50px',
        cursor: 'pointer',
        textTransform: 'uppercase',
        fontFamily: 'Roboto',
        fontWeight: '700',
        fontSize: '16px',
        minWidth: '233px',
        transform: 'scale(1.0)',
        transition: 'all .5s ease-in-out',
        '&:hover': {
            // background: '#06748C',
            // border: '1px solid #06748C',
            transform: 'scale(1.05)',
            transition: 'all .5s ease-in-out'
        },
        '&:disabled': {
            background: '#E6E6E6 !important',
            border: '1px solid #E6E6E6 !important',
            color: '#565656 !important',
            fontWeight: '400 !important',
            cursor: 'auto !important'
        }
    },
    link: {
        textDecoration: 'none',
    }
});

const ButtonCustom = ({
        id,
        type,
        title, 
        disabled, 
        background, 
        hoverBg, 
        color, 
        hoverColor, 
        border, 
        hoverBorder,
        borderRadius, 
        boxShadow, 
        boxShadowHover, 
        textTransform,
        onClick
    }) => {
    const styles = useStyles();

    const [isHovering, setIsHovering] = useState(false);

    const handleMouseEnter = () => {
        setIsHovering(true);
    };

    const handleMouseLeave = () => {
        setIsHovering(false);
    };
    
    return (
        <button 
            id={id}
            type={ type || "submit"}
            className={styles.button} 
            disabled={disabled} 
            onClick={onClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={{ 
                textTransform: textTransform ? textTransform : '',
                borderRadius: borderRadius ? borderRadius : '',
                boxShadow: isHovering ? boxShadowHover : boxShadow,
                background: isHovering ? hoverBg : background,
                border: isHovering ? hoverBorder : border,
                color: isHovering ? hoverColor : color,
            }}
        >
            {title}
        </button>
    )
}

export default ButtonCustom
