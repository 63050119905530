import React from 'react';
import ErrorCustom from '../../components/ErrorCustom';
import ErrorNotFoundIcon from '../../assets/ErrorNotFoundIcon.svg';
import { useNavigate } from 'react-router-dom';
const ErrorNotFound = () => {
    const navigate = useNavigate();

    
    const handleGoToHome = () => {
        navigate('/activacion-mandato')
    }
    return (
        <ErrorCustom 
            icon={ErrorNotFoundIcon}
            title={'No encontramos la página que buscas'}
            // descriptionBolded={'Debes iniciar sesión desde el menú de aplicaciones'}
            // descriptionHighlighted={'Si tienes alguna duda comunícate con tu ejecutivo comercial para mayor información.'}
            buttons={[
                {
                    title: 'Ir al inicio',
                    disabled: false,
                    // background: '#FFFFFF',
                    // border: '1px solid #1A1446',
                    // borderRadius: '5px',
                    // color: '#1A1446',
                    // hoverBorder: '1px solid #06748C',
                    background: '#65A518',
                    color: '#FFFFFF',
                    // boxShadow: '0px 3px 0px #C0BFC0',
                    // boxShadowHover: '0px 3px 0px #1A1446',
                    textTransform: 'none',
                    onClick: handleGoToHome,
                }
            ]}
            horizontalLine={true}
        />
    )
}

export default ErrorNotFound
