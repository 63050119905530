import { makeStyles } from '@mui/styles';
import React from 'react';
import LibertyLogo from '../assets/LogoLibertyHDIVertical.svg';
import LogoDefensoria from '../assets/LogoDefensoria.png';
import IconLinkedin from '../assets/IconLinkedin.svg';
import IconFacebook from '../assets/IconFacebook.svg';

const useStyles = makeStyles({
    footer: {
        backgroundColor: '#C0BFC0',
        padding:' 1rem 0 6rem',
    },
    container: {
        maxWidth: '1170px',
        margin: '0 auto',
        padding: '0 15px',
        display: 'flex',
        justifyContent: 'space-between',
        ["@media (min-width: 960px)"]: {
            flexDirection: 'row',
            justifyContent: 'initial',
        }
    },
    branding: {
        width: '116px',
        marginTop: '1em',
        ["@media (min-width: 960px)"]: {
            flex: '1 1 15%',
            marginLeft: 'auto',
            textAlign: 'right',
        }
    },
    optionsContainer: {
        ["@media (min-width: 960px)"]: {
            flex: '1 1 100%',
            marginLeft: 'auto',
            textAlign: 'right',
        },
        ["@media (max-width: 768px)"]: {
            display: 'none'
        }
    },
    options: {
        paddingLeft: '1em',
        listStyleType: 'none',
        display: 'flex',
        justifyContent: 'space-between'
    },
    menuItem: {
        listStyle: 'none',
        textAlign: 'left',
        lineHeight: '1.4',
    },
    menuItemTitle: {
        color: '#565656',
        fontSize: '0.875rem',
        textDecoration: 'none',
        fontWeight: '600',
        display: 'block',
        marginBottom: '0.5rem',
    },
    menuItemLink: {
        color: '#565656',
        fontSize: '0.875rem',
        textDecoration: 'none',
        fontWeight: '300',
        '&:hover': {
            textDecoration: 'underline'
        }
    },
    pl0: {
        paddingLeft: '0'
    },
    social: {
        marginBottom: '1rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',

        ["@media (min-width: 960px)"]: {
            flex: '0 1 30%',
            marginBottom: '0',
            marginLeft: '1rem',
        }
    },
    socialMenu: {
        listStyle: 'none',
        margin: '0',
        padding: '0',
        display: 'flex',
    },
    socialMenuItem: {
        marginBottom: '0.25rem'
    },
    socialMenuLink: {
        display: 'flex',
        flexFlow: 'row nowrap',
        alignItems: 'center',
        textDecoration: 'none',
        backgroundColor: '#fff',
        margin: '0 0 0 10px',
        borderRadius: '50%',
    }
});

const items = [
    {
        title: 'Nuestra Compañía',
        url: null,
        subItems: [
            {
                title: 'Quienes Somos',
                url: 'https://www.liberty.cl/nuestra-compania/quienes-somos'
            },
            {
                title: 'Organización',
                url: 'https://www.liberty.cl/nuestra-compania/organizacion'
            },
            {
                title: 'Gobierno Corporativo',
                url: 'https://www.liberty.cl/nuestra-compania/gobierno-corporativo'
            },
            {
                title: 'Publicaciones',
                url: 'https://www.liberty.cl/nuestra-compania/publicaciones'
            },
            {
                title: 'Canal de compliance e integridad',
                url: 'https://www.liberty.cl/nuestra-compania/canal-de-integridad'
            },
            {
                title: 'Términos de Uso y Política de Privacidad',
                url: 'https://www.liberty.cl/nuestra-compania/terminos-de-uso-y-privacidad'
            },
        ],
    },
    {
        title: 'Nuestros Seguros',
        url: null,
        subItems: [
            {
                title: 'Seguro Automotriz',
                url: 'https://www.liberty.cl/nuestros-seguros/seguro-automotriz'
            },
            {
                title: 'Seguro de Hogar',
                url: 'https://www.liberty.cl/nuestros-seguros/seguro-de-hogar'
            },
            {
                title: 'Seguros para Empresas',
                url: 'https://www.liberty.cl/nuestros-seguros/seguros-para-empresas'
            },
            {
                title: 'Consejos Liberty',
                url: 'https://www.liberty.cl/nuestros-seguros/liberty-te-aconseja'
            },
        ],
    },
    {
        title: 'Centro de Siniestros',
        url: null,
        subItems: [
            {
                title: 'Automotriz',
                url: 'https://www.liberty.cl/centro-de-siniestros/automotriz'
            },
            {
                title: 'Hogar y otros',
                url: 'https://www.liberty.cl/centro-de-siniestros/centro-de-siniestro-de-hogar-y-otros'
            }
        ],
    },
    {
        title: 'Servicio al Cliente',
        url: null,
        subItems: [
            {
                title: 'Nuevos Clientes',
                url: 'https://www.liberty.cl/servicio-al-cliente/nuevos-clientes'
            },
            {
                title: 'Pago en Línea',
                url: 'https://www.liberty.cl/pago-en-linea'
            },
            {
                title: 'Contáctanos',
                url: 'https://www.liberty.cl/contactanos'
            },
            {
                title: 'Red de Sucursales',
                url: 'https://www.liberty.cl/servicio-al-cliente/red-de-sucursales'
            },
            {
                title: 'Preguntas frecuentes',
                url: 'https://www.liberty.cl/servicio-al-cliente/preguntas-frecuentes'
            },
            {
                title: 'Siniestros detectados, no reportados',
                url: 'https://www.liberty.cl/sites/default/files/pdf/siniestros-detectados-y-no-reportados-2021-1pdf.pdf'
            },
            {
                title: 'Defensor del Asegurado',
                url: 'https://www.ddachile.cl/Inicio.aspx?M=0F4F1745527C84993BCD7EDC91EA748E5CB53DBA'
            }
            
        ],
    },
    {
        title: 'Accesos',
        url: null,
        subItems: [
            {
                title: 'Clientes',
                url: 'https://www.liberty.cl/clientes'
            },
            {
                title: 'Corredores',
                url: 'https://www.liberty.cl/corredores'
            },
            {
                title: 'Colaboradores',
                url: 'https://www.liberty.cl/colaboradores'
            },
        ],
    },
];

const Footer = () => {
    const styles = useStyles();

    return (
        <footer className={styles.footer}>
            <div className={styles.container}>
                <div className={styles.branding}>
                    <a style={{backgroundColor: 'transparent'}} href="https://www.liberty.cl/" >
                        <img 
                            style={{
                                display: 'block',
                                height: 'auto',
                                maxWidth: '100%',
                            }} 
                            src={LibertyLogo} alt="Logo" 
                        />
                    </a>
                </div>

                <div className={styles.optionsContainer}>       
                    <ul className={styles.options}>
                        { items.map((item, index) => (
                            <li className={styles.menuItem} key={index}>
                                <span className={styles.menuItemTitle}>{item.title}</span>

                                <ul className={styles.pl0}>
                                    { item.subItems.map((subItem, indexSubItem) => (
                                        <li className={styles.menuItem} key={indexSubItem}>
                                            <a href={subItem.url} title={subItem.title} className={styles.menuItemLink}>{subItem.title}</a>
                                        </li>
                                    ))}
                                </ul>    
                            </li>
                        ))}
                    </ul>
                </div>

                <div className={styles.social}>
                    <h4>Síguenos</h4>
  
                    <ul className={styles.socialMenu}>
                        <li className={styles.socialMenuItem}>
                            <a className={styles.socialMenuLink} target="_blank" href="https://www.facebook.com/LibertySegurosCl">
                                <img src={IconFacebook} alt="Liberty Facebook" />
                            </a>
                        </li>
          
                        <li className={styles.socialMenuItem}>
                            <a className={styles.socialMenuLink} target="_blank" href="https://www.linkedin.com/company/liberty-seguros-andes/">
                                <img src={IconLinkedin} alt="Liberty Linkedin" />
                            </a>
                        </li>
                    </ul>

                    <a href="https://www.ddachile.cl/" target="_blank">
                        <img className="logo__image" src={LogoDefensoria} alt="Defensor del asegurado" />
                    </a>
                </div>
                
            </div>
        </footer>
    )
}

export default Footer
