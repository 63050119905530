import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { TextField } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import BreadcrumbsCustom from '../components/BreadcrumbsCustom';
import AlertCustom from '../components/AlertCustom';
import ButtonCustom from '../components/ButtonCustom';

import IconWarning from '../assets/IconWarning.svg';
import { URL_INICIO, URL_MANDATOS, URL_POLIZAS } from '../config/Constants';
import Banner from '../components/Banner';
import Loading from '../components/Loading';
import ModalCustom from '../components/ModalCustom';
import { revalidateToken } from '../auth/getToken';


const useStyles = makeStyles({
    activateMandate: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '40px',
        maxWidth: '1224px',
        width: '100%',
        margin: '0 auto',
        marginTop: '32px',
        ["@media (max-width: 1280px)"]: {
            width: '96%'
        },
        ["@media (max-width: 450px)"]: {
            marginTop: '4rem'
        },
        ["@media (max-width: 400px)"]: {
            width: '94%'
        }
    },
    title: {
        letterSpacing: '0px',
        color: '#565656',
        textAlign: 'center',
        fontSize: '32px',
        fontWeight: '700'
    },
    pretitle: {
        color: '#343741',
        textAlign: 'center',
        font: 'Roboto-Regular',
        letterSpacing: '0px',
        fontSize: '18px',
        fontWeight: '400'
    },
    warning: {
        color: '#006729',
        letterSpacing: '0px',
        fontSize: '14px',
        fontWeight: '400'
    },
    form: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        paddingBottom: '100px'
    },
    inputRut: {
        width: '100%',
        '& .MuiFormHelperText-root': {
           marginLeft: '0px',
           marginRight: '0px',
        },

        '&.MuiTextField-root': {
            '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#565656'
            },

            '& .MuiFormLabel-root.Mui-focused': {
                color: '#565656'
            }
        }
    },
    errorLabel: {
        color: '#D32F2F',
        fontSize: '12px',
        textAlign: 'left',
        letterSpacing: '0px'
    },
    infoLabel: {
        color: '#808080',
        fontSize: '12px',
        textAlign: 'left',
        letterSpacing: '0px',
        marginTop: '8px',
        marginBottom: '22px'
    },
    infoModal: {
        color: 'dark',
        backgroundColor: '#FFFFFF',
        // width: '100%',
        // height: '464px',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        // justifyContent: 'center',
        alignItems: 'center',
        padding: '40px 70px'
    },
    closeIconModal: {
        position: 'absolute',
        right: '30px',
        top: '30px',
        cursor: 'pointer'
    },
    warningIconModal: {
        paddingTop: '20px'
    },
    descriptionContainer: {
        maxWidth: '483px',
        textAlign: 'center'
    },
    descriptionModal: {
        fontSize: '16px',
        fontWeight: '400',
        color: '#343741',
        marginTop: '0px'
    },
    buttonsInfoModal: {
        display: 'flex',
        flexDirection: 'row',
        gap: '20px',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '26px'
    },
    mb_25: {
        marginBottom: '25px'
    },
    mb_32: {
        marginBottom: '32px'
    }
});


const ActivateMandate = () => {
    const styles = useStyles();
    const navigate = useNavigate();
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [rut, setRut] = useState('');
    const [data, setData] = useState({});
    const [url, setUrl] = useState('');
    const [error, setError] = useState({});
    // Estado para manejar loading...
    const [openLoadingModal, setOpenLoadingModal] =  useState(false);
    // Estado para manejar modal de Error: Rut ingresado no encontrado
    const [openInfoModal, setOpenInfoModal] =  useState(false);
    // Estado para manejar modal de Si o No en caso de encontrar mandatos activos
    const [openUseMandatesModal, setOpenUseMandatesModal] = useState(false);

    const [tokenCaptcha, setTokenCaptcha] = useState(false);
    
    const [selectedRadioValue, setSelectedRadioValue] = useState(null);

    const handleChangeRadioValue = (e) => {
        setSelectedRadioValue(e.target.value);
    };
    
    const handleOpenLoadingModal = () => {
        setOpenLoadingModal(true);
    };

    const handleCloseLoadingModal = (event, reason) => {
        // No se cierra modal en caso de hacer click fuera de este
        if (reason && reason == "backdropClick") 
        return;

        setOpenLoadingModal(false);
    };

    const handleOpenInfoModal = () => {
        setOpenInfoModal(true);
    };

    const handleCloseInfoModal = () => {
        setOpenInfoModal(false);
    };

    const handleOpenUseMandatesModal = () => {
        setOpenUseMandatesModal(true);
    };

    const handleCloseUseMandatesModal = () => {
        setOpenUseMandatesModal(false);
        setSelectedRadioValue(null);
    };



    const items = [
        {
            name: 'Inicio',
            url: URL_INICIO
        }
    ];

    const onChangeRut = (e) => {
        setRut(e.target.value.toUpperCase());
        setUrl(`/activacion-mandato/activar`);
    }

    const checkRut = (str) =>{
        // Se valida que solo pueda iniciar e ingresar numeros y que pueda terminar con numeros o una K
        let rutCheck = /^[0-9]+[0-9K]$/;
        if(rutCheck.test(str)){
            return true;
        }
        else{
            return false;
        }
    }

    const validateInsuredDni = () => {
        setError({status: false});
        let isValid = true;

        if(rut.length < 8) {
            setError({
                status: true,
                msg: 'ⓘ El RUT ingresado no cumple con los caracteres mínimos. Mínimo 8 caracteres'
            });
            isValid = false;
            return;
        }

        if(rut.length > 9) {
            setError({
                status: true,
                msg: 'ⓘ El RUT ingresado no cumple con los caracteres máximos. Máximo 9 caracteres'
            });
            isValid = false;
            return;
        }

        if(!checkRut(rut) ) {
            setError({
                status: true,
                msg: 'ⓘ El formato no es válido. Por favor corrige el dígito de verificación'
            });
            isValid = false;
            return;
        }
        
        return isValid;
    }

    // POST - obtener polizas activas por rut ingresado
    const getPolizas = async() => {
        handleOpenLoadingModal();

        // Se valida token
        await revalidateToken();

        let payerData = null;

        const requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${sessionStorage.token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ rut: rut  }),
        };

        await fetch(
            URL_POLIZAS, 
            requestOptions
        ).then(response => response.json())
        .then(res => {
            payerData = res.data; 
            
            if (!payerData.name && payerData.lastname) {
                payerData = {
                    dni: payerData.dni,
                    name: payerData.lastname,
                    lastname: '',
                    email: payerData.email
                }
            }
            
            // setData(payerData);
            handleCloseLoadingModal();
        })
        .catch(error => {
            setData(null);
            handleCloseLoadingModal();
            handleOpenInfoModal();
            console.error('ERROR getPolizas: ', error);
        })

        return payerData;
    }

    // POST - obtener mandatos activos (cuentas corrientes)
    const getActiveMandates = async() => {
        handleOpenLoadingModal();

        // Se valida token
        await revalidateToken();

        const requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${sessionStorage.token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ rut: rut  })
        };

        return await fetch(
            URL_MANDATOS, 
            requestOptions
        ).then(response => response.json())
        .then(res => {
            handleCloseLoadingModal();
            return res.data;
        })
        .catch(error => {
            handleCloseLoadingModal();
            console.error('ERROR getActiveMandates: ', error);
        })
    }

    const onSubmit = async(e) => {
        e.preventDefault();

        handleReCaptchaVerify();

        const isValid = validateInsuredDni();

        if(isValid) {
            let dataToNextPage;
            // Se obtienen polizas del rut ingresado
            const policy = await getPolizas();

            // Solo si tiene polizas, se continua con el proceso, de caso contrario, se abre popup de rut no encontrado
            if (policy) {
                // Se obtiene si tiene mandatos activos
                const mandatos = await getActiveMandates();

                // Se setea variables iniciales que iran a la siguiente pantalla (datos cliente)
                dataToNextPage = {
                    ...policy,
                    mandates: []
                }

                if (mandatos.length > 0) {
                    // SI TIENE MANDATOS ACTIVOS: se abre modal del Si o No, y se actualiza variable para pasar a siguiente pantalla
                    handleOpenUseMandatesModal();
                    
                    dataToNextPage = {
                        ...dataToNextPage,
                        mandates: mandatos
                    }

                    setData(dataToNextPage);
                } else { 
                    // NO TIENE MANDATOS ACTIVOS: va a pagina cliente con data sin preguntar si o no, ya que no posee mandatos activos
                    dataToNextPage = {
                        ...dataToNextPage,
                        optionSelected: 'NO'
                    }
                    setData(dataToNextPage);
                    navigate(url, {state: dataToNextPage})
                }
            }
           
        } else {
            setUrl(``);
            console.log('ERROR EN FORM')
        }
    }

    // Funcion si es que tiene mandatos activos, boton "Siguiente" de Popup Si o No
    const onSubmitWithMandateSelected = async() => {
        const dataToNextPage = {
            ...data,
            optionSelected: selectedRadioValue.toUpperCase()
        }

        navigate(url, {state: dataToNextPage});
    }

    // Create an event handler so you can call the verification on button click event or form submit
    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
        // console.log('Execute recaptcha not yet available');
        setTokenCaptcha(false);
        return;
        }

        const token = await executeRecaptcha('yourAction');
        // Do whatever you want with the token
        // console.log(token)
        setTokenCaptcha(token);
    }, [executeRecaptcha]);

    // You can use useEffect to trigger the verification as soon as the component being loaded
    useEffect(() => {
        handleReCaptchaVerify();
    }, [handleReCaptchaVerify]);
    
    return (
        <>
            <Banner />
            <div className={styles.activateMandate}>
                <BreadcrumbsCustom items={items}/>
            
                <h1 className={styles.title}>Activa el mandato PAC</h1>
                <span className={styles.pretitle}>Completa los siguientes datos:</span>

                <p className={styles.warning}>Los campos con (*) son obligatorios</p>

                <div className={styles.mb_25}>
                    <AlertCustom 
                        borderLeftColor="#65a518"
                        infoIcon
                        color="#65a518"
                        text={'Ten en cuenta que debes ingresar el Rut del pagador, asegurado o contratante al que se encuentra asociada la póliza'}
                    />
                </div>

                <form className={styles.form} onSubmit={onSubmit}>
                    <div>
                        <p className={styles.errorLabel}>{ error.msg }</p>
                        <TextField 
                            // inputRef={refInputRut}
                            inputRef={input => input && input.focus()}
                            className={styles.inputRut}
                            required
                            label="Rut"
                            placeholder="Ingresar Rut"
                            InputLabelProps={{ shrink: true }}
                            value={rut}
                            onChange={(e) => onChangeRut(e)}
                            error={error.status ? true : false}
                        />
                        <p className={styles.infoLabel}>Escribe el RUT sin puntos ni guión y con dígito verificador. Ejemplo: 12345678K</p>
                    </div>

                    <ButtonCustom 
                        // type="input"
                        id='activacion-mandato-paso-1-consulta'
                        title={'Consulta aquí'} 
                        disabled={tokenCaptcha ? false : true}
                        background={'#65A518'}
                        color={'#FFFFFF'}
                        // onClick={onSubmit} 
                        url={url}
                    />
                </form>

                <ModalCustom 
                    openModal={openInfoModal}
                    handleCloseModal={handleCloseInfoModal}
                    icon={IconWarning}
                    title={'Lo sentimos'}
                    descriptions={[
                        'No encontramos información asociada al RUT ingresado en tu consulta.',
                        'Por favor valida, modifica el RUT y consulta nuevamente.'
                    ]}
                    buttons={[
                        {
                            title: 'Modificar rut',
                            disabled: false,
                            // background: '#99E5EA',
                            // border: '1px solid #99E5EA',
                            // color: '#1A1446',
                            // hoverBorder: '1px solid #99E5EA',
                            // hoverBg: '',
                            // hoverColor: '',
                            background: '#65A518',
                            color: '#FFFFFF',
                            onClick: handleCloseInfoModal,
                        }
                    ]}
                />

                <ModalCustom 
                    openModal={openUseMandatesModal}
                    handleCloseModal={handleCloseUseMandatesModal}
                    alertBox={{
                        width: '100%',
                        borderLeftColor: "#65a518",
                        infoIcon: false,
                        successIcon: true,
                        color: '#65a518',
                        text: '¡Ya cuentas con un mandato PAC activo!'
                    }}
                    title={'¿Deseas asociar tu póliza con una cuenta corriente?'}
                    radioButtons={[
                        {
                            id: 1,
                            value: 'Si',
                            selectedRadioValue: selectedRadioValue,
                            onChange: handleChangeRadioValue
                        },
                        {
                            id: 2,
                            value: 'No',
                            selectedRadioValue: selectedRadioValue,
                            onChange: handleChangeRadioValue
                        }
                    ]}
                    buttons={[
                        {
                            title: 'Cancelar',
                            disabled: false,
                            // background: '#FFFFFF',
                            // border: '1px solid #1A1446',
                            // color: '#1A1446',
                            // hoverBorder: '1px solid #06748C',
                            // hoverBg: '',
                            // hoverColor: '',
                            background: '#FFFFFF',
                            hoverBg: '#FFFFFF',
                            border: '2px solid #65A518',
                            hoverBorder: '2px solid #65A518',
                            color: '#000000',
                            hoverColor: '#000000',
                            onClick: handleCloseUseMandatesModal,
                        },
                        {
                            title: 'Siguiente',
                            disabled: selectedRadioValue ? false : true,
                            // background: '#99E5EA',
                            // border: '1px solid #99E5EA',
                            // color: '#1A1446',
                            // hoverBorder: '1px solid #99E5EA',
                            // hoverBg: '',
                            // hoverColor: '',
                            background: '#65A518',
                            color: '#FFFFFF',
                            onClick: onSubmitWithMandateSelected,
                        }
                    ]}
                />
                
                <Loading 
                    openLoadingModal={openLoadingModal}
                    handleCloseLoadingModal={handleCloseLoadingModal}
                />
            </div>
        </>
    )
}

export default ActivateMandate;
