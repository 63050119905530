import React from 'react'
import { makeStyles } from '@mui/styles';
import ButtonCustom from './ButtonCustom'
import IconClose from '../assets/IconClose.svg';
import { Dialog, Fade, Radio } from '@mui/material';
import AlertCustom from './AlertCustom';

const useStyles = makeStyles({
    title: {
        letterSpacing: '0px',
        color: '#565656',
        textAlign: 'center',
        fontSize: '32px',
        fontWeight: '700',
        ["@media (max-width: 660px)"]: {
            fontSize: '24px',
        },
    },
    infoModal: {
        color: 'dark',
        backgroundColor: '#FFFFFF',
        // width: '100%',
        // height: '464px',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        // justifyContent: 'center',
        alignItems: 'center',
        padding: '60px 70px',
        ["@media (max-height: 620px)"]: {
            padding: '60px 40px',
        },
        ["@media (max-width: 390px)"]: {
            padding: '60px 40px',
        },
    },
    closeIconModal: {
        position: 'absolute',
        right: '30px',
        top: '30px',
        cursor: 'pointer'
    },
    warningIconModal: {
        paddingTop: '20px'
    },
    descriptionContainer: {
        maxWidth: '483px',
        textAlign: 'center'
    },
    descriptionModal: {
        fontSize: '16px',
        fontWeight: '400',
        color: '#343741',
        marginTop: '0px',
        whiteSpace: 'pre-line'
    },
    buttonsInfoModal: {
        display: 'flex',
        flexDirection: 'row',
        gap: '20px',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '26px',
        ["@media (max-width: 660px)"]: {
            flexDirection: 'column-reverse'
        },
    },
    radioButtonsContainer: {
        display: 'flex',
        flexDirection: 'row',
        gap: '50px'
    },
    radioContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    radioLabel: {
        fontSize: '16px',
        fontWeight: '400px'
    }
});


const ModalCustom = ({ 
    openModal,
    handleCloseModal,
    alertBox,
    icon, 
    title, 
    descriptions, 
    radioButtons,
    buttons,
}) => {
    const styles = useStyles();


    return (
        <Dialog
            maxWidth={'md'}
            open={openModal}
            onClose={handleCloseModal}
            BackdropProps={{
                style: {
                    backgroundColor: 'rgb(101 165 24 / 90%)'
                }
            }}
            PaperProps={{
                style: {
                    backgroundColor: "transparent",
                    boxShadow: "none",
                    overflow: 'hidden'
                },
            }}
        >
            <Fade in={openModal}>
                <div className={styles.infoModal}>
                    <picture className={styles.closeIconModal} onClick={handleCloseModal}>
                        <img src={IconClose} />
                    </picture>

                    { alertBox 
                        ? <AlertCustom 
                            width={alertBox.width}
                            borderLeftColor={alertBox.borderLeftColor}
                            infoIcon={alertBox.infoIcon}
                            successIcon={alertBox.successIcon} 
                            color={alertBox.color}
                            text={alertBox.text}
                          />
                        : null
                    }
                    
                    { icon ? 
                            <picture className={styles.warningIconModal}>
                                <img src={icon} />
                            </picture>
                        : 
                            null
                    }
                    
                    { title ? <h1 className={styles.title}>{ title }</h1> : null }

                    <div className={styles.descriptionContainer}>
                        { descriptions 
                            ? 
                                descriptions.map((description, index) => (
                                    <p key={index} className={styles.descriptionModal}>{description}</p>
                                ))
                            :
                                null
                        }
                    
                    </div>

                    { radioButtons 
                        ? 
                            <div className={styles.radioButtonsContainer}>
                                { radioButtons.map((radio) => (
                                    <div className={styles.radioContainer} key={radio.id}>
                                        <Radio
                                            checked={radio.selectedRadioValue === radio.value}
                                            onChange={radio.onChange}
                                            value={radio.value}
                                        />
                                        <p className={styles.radioLabel}>{radio.value}</p>
                                    </div>
                                ))}
                            </div>
                        : 
                            null
                    } 

                    <div className={styles.buttonsInfoModal}>
                        { buttons 
                            ?
                                buttons.map((button, index) => (
                                        <ButtonCustom 
                                            key={index}
                                            title={ button.title || '' } 
                                            disabled={ button.disabled || '' }
                                            background={ button.background || '' }
                                            hoverBg={ button.hoverBg || '' }
                                            color={ button.color || '' }
                                            hoverColor={ button.hoverColor || '' }
                                            border={ button.border || '' }
                                            hoverBorder={ button.hoverBorder || '' }
                                            onClick={ button.onClick || '' }
                                        />
                                ))
                            :
                                null
                        }
                    </div>
                </div>
            </Fade>
        </Dialog>
    )
}

export default ModalCustom;